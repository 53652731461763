.widget {
    background-color: #fff;
    // margin-right: 16px;
    margin-bottom: 16px;
    padding: 16px;
    box-shadow: 0 0 3px #ccc;
    border-radius: 3px;
    flex: 1;
    

    .widget-body{
        overflow-x: auto;
    }

    .widget-title {
        font-size: 16px;
        border-bottom: 1px solid #ccc;
        text-align: center;
        margin-bottom: 8px;
        padding-bottom: 8px;
        margin-top: -8px;
        font-weight: 500;
    }
}

.table {
    width: 100%;
    td,th{
        padding: 3px 5px;
        white-space: nowrap;
    }
    tr:nth-child(2n){
       background-color: #f1f1f1; 
    }
}

