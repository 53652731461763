#root {
    height: 100%;
    // background: rebeccapurple;
}

.ant-dropdown-menu-item-group-list {
    padding-left: 0;
    list-style-type: none;
}

.ant-btn {
    margin-right: 5px;
    margin-bottom: 5px;
}

.ant-form {

    .ant-form-item {
        margin-bottom: 10px;

        .ant-form-item-label {
            line-height: 20px;
        }
    }

    legend {
        font-size: 14px;
        margin-top: 30px;
    }
}

.ant-table-content td {
    padding: 2px 5px !important;
    font-size: 12px;

    .react-json-view{
        display: inline-block;
    }
}

.ant-drawer-body .ant-tabs-tabpane {
  padding: 0 16px;
}