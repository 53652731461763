table{
    background: #fff;
}
.ant-layout-sider {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
}
.ant-layout-content {
    overflow-x: auto;
}
.ant-drawer-content-wrapper {
    max-width: 100%;
}

.ant-divider-horizontal.ant-divider-with-text-center::before, .ant-divider-horizontal.ant-divider-with-text-left::before, .ant-divider-horizontal.ant-divider-with-text-right::before, .ant-divider-horizontal.ant-divider-with-text-center::after, .ant-divider-horizontal.ant-divider-with-text-left::after, .ant-divider-horizontal.ant-divider-with-text-right::after {
    position: unset;
}
